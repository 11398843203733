/* eslint-disable menti-react/filename-convention--jsx */
import { showNewMessage } from '@mentimeter/intercom';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Heading } from '@mentimeter/ragnar-ui/typography';

export const ErrorFallback = ({
  showHomeButton = false,
}: {
  showHomeButton?: boolean;
}) => (
  <Box p={5} width="100%" alignItems="center" justifyContent="center">
    <Heading>Oops, something went wrong</Heading>
    <Text textAlign="center">
      Try reloading the page, or contact support if this continues.
    </Text>
    <Box alignItems="center">
      <Box
        alignItems="center"
        flexDirection={['column', 'row']}
        mt={4}
        mb={3}
        gap={2}
      >
        <Button variant="primary" onClick={() => window.location.reload()}>
          Reload
        </Button>
        {showHomeButton && (
          <Button variant="tertiary" href="/app/home">
            Go to homepage
          </Button>
        )}
      </Box>
      <Button
        variant="subtle"
        onClick={(ev) => {
          ev.preventDefault();
          showNewMessage();
        }}
        m={1}
      >
        Chat with support
      </Button>
    </Box>
  </Box>
);
